<template>
  <div class="flex justify-center w-full">
    <div class="container w-full px-[24px] sm:px-[48px] lg:px-[64px]">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.container {
  max-width: var(--section-max-width, 1516px);
}
</style>
